import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://www.youtube.com/embed/SKMm1kBx6wA"
    notesSrc="http://bible.com/events/7170652"
    notesText="Sermon Notes"
    bibleGroupSrc="https://www.docdroid.net/ct88p72/bible-group-homework-4-5-pdf"
    bibleGroupText="Bible Group Homework"
  >
    <SEO title="God is Glorified - When People Pray" />
  </Layout>
)

export default SermonPost
